<script setup lang="ts">
import dayjs from "dayjs";

const emit = defineEmits<{ (event: "closeSidebar"): void }>();

const { t } = useT();
const { questData } = useGameQuest();
const { open } = useFunrizeModals();

const { durationLeft: durationLeftQuestDay, reset: resetQuestDay } = useCountdown({
	timestamp: questData.value?.questInfo?.end || "",
	formatToken:
		dayjs(questData.value?.questInfo?.end ?? "").diff(dayjs(), "d") >= 1
			? "DD[d ] HH[h ]mm[m ]"
			: "HH[h ] mm[m ] ss[s ]"
});

const handleOpenClick = () => {
	emit("closeSidebar");
	if (questData.value?.questInfo?.isAvailable) {
		open("LazyOModalFreeSpin");
		return;
	}
	open("LazyOModalFreeSpinAlternative");
};

watch(
	() => questData.value?.questInfo?.end,
	(value) => {
		resetQuestDay(value || "");
	}
);
</script>

<template>
	<div class="free-spin-sidebar">
		<AText :size="14" :modifiers="['capitalize', 'bold']" class="text-cannes">
			{{ t("freeSpin.sidebar.title") }}
		</AText>
		<div v-if="!!questData?.dayInfo?.isCompleted" class="quest-status">
			<AText :size="12" class="text-conakry">
				{{ t("freeSpin.sidebar.status.txt1") }}
			</AText>
			<ABadge variant="info" background="rgba(0, 255, 99, 0.20)" autosize>
				<AText :size="14" :modifiers="['bold', 'uppercase']" class="text-cixi">
					{{ t("freeSpin.sidebar.status.txt2") }}
				</AText>
			</ABadge>
		</div>
		<template v-else>
			<AButton variant="primary" size="xs" class="action-btn" :modifiers="['wide']" @click.stop="handleOpenClick">
				<AText :size="12" :modifiers="['uppercase', 'bold']" class="text-cannes">{{
					t("freeSpin.sidebar.btn.name")
				}}</AText>
			</AButton>
			<AText :size="10" class="text-conakry">
				<i18n-t keypath="freeSpin.sidebar.timer">
					<template #timer>
						<AText :size="10" class="text-cannes" :modifiers="['bold']">{{ durationLeftQuestDay }}</AText>
					</template>
				</i18n-t>
			</AText>
		</template>
		<NuxtImg
			src="/nuxt-img/free-spin-quest/sidebar-img.webp"
			width="160"
			height="268"
			loading="lazy"
			alt="free-spin-quest"
		/>
	</div>
</template>

<style lang="scss" scoped>
$gradient-bg: linear-gradient(281deg, #9700bd 0%, #2131d9 100%);
.free-spin-sidebar {
	padding: 8px 42px 8px 8px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	position: relative;
	min-height: 66px;
	flex-shrink: 0;
	background: $gradient-bg;

	.quest-status {
		display: flex;
		gap: 4px;
		align-items: center;
		margin-top: auto;
	}

	.action-btn {
		max-width: 120px;
		border-radius: 50px;
		min-height: 28px;
	}

	img {
		position: absolute;
		top: calc(50% - 33px);
		right: 0;
		width: auto;
		height: 66px;
	}
}
</style>
